/**
 * clickMaskingRow
 * @example {id: 'cGF8NjMz', packageId: 633, percentage: 20, location: 'US', language: 'en', referrer: 'nobody'}
 */
export type clickMaskingSpecs = {
	percentage: number;
	country: string[];
	hideOffer: boolean;
};

export type ClickMaskingConfig = { [packageId: number]: clickMaskingSpecs };

const config: ClickMaskingConfig = {
	73: {
		// Tubi
		percentage: 50,
		country: ['ALL'],
		hideOffer: false,
	},
	300: {
		// Pluto TV
		percentage: 50,
		country: ['ALL'],
		hideOffer: false,
	},
};

export { config as clickMaskingConfig };

/**
 * Returns true if the givven package should be click masked.
 * Pass `{ andHide: true }` to also consider if the package offers should be completely hidden.
 */
export function shouldClickMask(packageId: number, country: string, { andHide = false } = {}) {
	const specs = config[packageId];
	if (!specs) return false;

	const shouldHide = andHide && specs.hideOffer;

	return (
		(specs.country.includes(country) || specs.country.includes('ALL')) &&
		Math.random() * 100 < specs.percentage &&
		(!andHide || shouldHide)
	);
}

/** More typesafe way to strip a URLs query parameters. */
export function clickMaskUrl(url: string) {
	const u = new URL(url);
	u.search = '';
	return u.href;
}
