import { defineComponent } from 'vue';
import { toValue } from '@vueuse/core';

import type { Offer, Package } from '@/@types/graphql-types';
import type {
	VisibilityChangedPayload,
	BatchImpressionTrackingOptions,
} from '@/helpers/composables/useBatchImpressionTracking';
import { useBatchImpressionTracking } from '@/helpers/composables/useBatchImpressionTracking';

import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { ClickoutContentType } from '@/interfaces/snowplow/clickout-context';
import {
	SnowplowClickoutContextGraphql,
	SnowplowTitleContextGraphql,
} from '@/helpers/tracking/providers/snowplow-contexts';

export type ImpressionTrackingOfferDetails = Pick<
	Offer,
	'id' | 'monetizationType' | 'presentationType' | 'retailPriceValue' | 'currency' | 'lastChangeRetailPriceValue'
> & {
	package: Pick<Package, 'packageId' | 'clearName'> & { technicalName?: Package['technicalName'] };
	isLeavingIconShown?: boolean;
	bundleId?: number;
	bundleTechnicalName?: string;
};

export type OfferVisibilityChangedPayload = VisibilityChangedPayload<ImpressionTrackingOfferDetails>;

export const observableOptions = {
	once: false,
	throttle: 300,
	intersection: {
		root: null,
		rootMargin: '0px 0px 0px 0px',
		threshold: 0.5, // 50% visibility of each element
	},
} as const;

export function useOfferImpressionTracking(options: BatchImpressionTrackingOptions = {}) {
	// let's derive a group id from title contexts if they're present.
	// this way, we can be sure that we send the title context with all offer impressions for that title.
	// it's problematic otherwise, if we're on the search page with different titles.
	const entityId = (toValue(options.additionalContexts) || [])
		.filter(context => context.__name.includes('title'))
		.map(context => (context as SnowplowTitleContextGraphql).jwEntityId)
		.find(Boolean);
	return useBatchImpressionTracking<ImpressionTrackingOfferDetails>({
		category: 'offer',
		toContext: getOfferImpressionContext,
		observableOptions: options.observableOptions ?? observableOptions,
		additionalContexts: options.additionalContexts,
		// groupId is needed in case we want to group batch impressions specifically
		groupId: entityId,
	});
}

/** Renderless version */
export default defineComponent({
	name: 'UseOfferImpressionTracking',
	props: ['observableOptions', 'additionalContexts'],
	setup(props, { slots }) {
		return () => slots.default?.(useOfferImpressionTracking(props)) ?? slots[0];
	},
});

export function getOfferImpressionContext(offerDetails: ImpressionTrackingOfferDetails) {
	if (offerDetails?.bundleId) {
		// package.technicalName is available only for bundles
		return TrackingHelper.getPackageContext(
			offerDetails.package.packageId,
			offerDetails.package.technicalName ?? '',
			'bundle',
			offerDetails.bundleId,
			offerDetails.bundleTechnicalName
		);
	}

	const contentType = offerDetails?.isLeavingIconShown ? ClickoutContentType.LeavingSoon : undefined;
	return SnowplowClickoutContextGraphql.fromProviderOffer(offerDetails, contentType);
}
