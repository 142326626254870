import { TitleOffers } from '@/interfaces/buybox';

export const packagesByMonetizationPotential = {
	US: {
		TVOD: [2, 10, 9, 529, 677, 68, 536, 179],
		SVOD: [350, 10, 9, 119, 337, 1899, 283, 257, 386, 531, 464, 43, 190, 2078, 358, 299, 444, 1793, 1948, 1875],
		FVOD: [2129, 1948, 1875],
	},

	GB: {
		TVOD: [2, 10, 9, 130, 68, 529, 677, 536, 189],
		SVOD: [350, 10, 9, 119, 283, 337, 1793, 41, 692, 464, 444],
		FVOD: [] as number[],
	},
	DE: {
		TVOD: [2, 10, 9, 677, 68, 536, 310, 133, 1829],
		SVOD: [350, 9, 119, 10, 337, 283, 30, 421, 29, 1856, 1756, 692, 444],
		FVOD: [] as number[],
	},
	ES: {
		TVOD: [2, 10, 9, 1717, 677, 68, 536],
		SVOD: [350, 9, 119, 10, 337, 283, 1899, 149, 1773, 63, 464, 692, 393, 444, 1838, 1875],
		FVOD: [1875],
	},
	IT: {
		TVOD: [2, 10, 9, 677, 68, 536],
		SVOD: [350, 9, 119, 10, 337, 283, 109, 39, 692, 444, 29, 696],
		FVOD: [696],
	},
	FR: {
		TVOD: [2, 10, 9, 239, 677, 68, 536, 310, 138],
		SVOD: [350, 9, 119, 10, 337, 283, 381, 2154, 1899, 239, 444, 692, 1967, 1875],
		FVOD: [1875],
	},
	MX: {
		TVOD: [2, 677, 536],
		SVOD: [350, 337, 283, 1899, 9, 119, 10, 464, 692, 444, 1875],
		FVOD: [1875],
	},
	BR: {
		TVOD: [2, 677, 68, 536, 1920],
		SVOD: [350, 337, 283, 1899, 9, 119, 1860, 692, 444, 1973, 1875],
		FVOD: [1875],
	},
};

export type CountriesToBeSorted = keyof typeof packagesByMonetizationPotential;
type VODType = keyof (typeof packagesByMonetizationPotential)[CountriesToBeSorted];

export const countriesToBeSortedByPotential = ['US', 'GB', 'DE', 'ES', 'IT', 'FR', 'MX', 'BR'];

function sortOffers(
	offers: TitleOffers['buy' | 'stream' | 'rent' | 'fast' | 'free' | 'cinema'],
	key: VODType,
	country: CountriesToBeSorted
) {
	if (packagesByMonetizationPotential[country][key].length === 0) return offers;
	return offers.slice().sort((a, b) => {
		const indexA = packagesByMonetizationPotential[country][key].indexOf(a.package.packageId);
		const indexB = packagesByMonetizationPotential[country][key].indexOf(b.package.packageId);

		const lengthOfArray = packagesByMonetizationPotential[country][key].length;
		// assign index of packageId in packagesByMonetizationPotential, if -1 assign length of array
		// sort ascending
		return (indexA > -1 ? indexA : lengthOfArray) - (indexB > -1 ? indexB : lengthOfArray);
	});
}

export function sortOffersByMonetizationPotential(offers: TitleOffers, country: CountriesToBeSorted) {
	if (!countriesToBeSortedByPotential.includes(country)) return offers;

	return {
		...offers,
		stream: sortOffers(offers.stream, 'SVOD', country),
		buy: sortOffers(offers.buy, 'TVOD', country),
		rent: sortOffers(offers.rent, 'TVOD', country),
		free: sortOffers(offers.free, 'FVOD', country),
	};
}
