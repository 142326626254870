
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { getCtr } from '@/helpers/modal-helper';
import BasicButton from '@/ui-components/BasicButton.vue';

@Component({
	name: 'BaseModal',
	components: {
		BasicButton,
	},
})
export default class BaseModal extends Vue {
	mCtr: any;

	@Prop({ default: '' }) title: string;
	@Prop({ default: '' }) subTitle: string;
	@Prop({ default: true }) closable: boolean;
	@Prop({ default: true }) hasHeader: boolean;
	@Prop({ default: false }) hasDoneButton: boolean;
	@Prop({ default: false }) disableDoneButton: boolean;

	async mounted() {
		this.mCtr = await getCtr();

		const modal = (await this.mCtr.getTop()) as HTMLIonModalElement;
		await modal?.onWillDismiss();
		this.$emit('closed');
	}

	get hasFooter() {
		return this.$slots.footer;
	}

	close() {
		this.mCtr?.dismiss().catch(() => {});
		this.$emit('closed');
	}
}
