import type { WebLocale } from '@/enums/web-locale';
import { BuyboxOffer } from '@/interfaces/buybox';

const sponsoredProviders: { [key in WebLocale]?: number[] } = {
	ar: [2, 3, 337, 350, 444, 521, 619],
	at: [2, 3, 9, 10, 40, 68, 99, 133, 310, 337, 350, 444, 521, 533],
	au: [2, 3, 10, 68, 99, 119, 134, 151, 223, 246, 269, 337, 350, 357, 378, 385, 444, 521, 536],
	be: [2, 3, 223, 310, 337, 350, 389, 444, 521],
	bg: [2, 350, 444, 521],
	bo: [2, 3, 337, 350, 444, 521, 619],
	br: [2, 3, 68, 119, 269, 337, 350, 444, 464, 521, 619],
	ca: [
		2, 3, 10, 68, 99, 143, 182, 194, 196, 197, 204, 205, 223, 294, 296, 331, 337, 350, 444, 464, 521, 529, 536, 587,
		588, 589, 605, 606, 608, 609, 611, 612,
	],
	ch: [2, 3, 68, 133, 337, 350, 389, 444, 521],
	cl: [2, 3, 337, 350, 444, 521, 619],
	co: [2, 3, 337, 350, 444, 521, 619],
	cr: [2, 3, 337, 350, 444, 521, 619],
	cz: [2, 3, 350, 444, 521],
	de: [
		2, 3, 9, 10, 29, 30, 40, 50, 68, 99, 133, 194, 195, 201, 204, 285, 286, 304, 310, 334, 337, 350, 389, 421, 444,
		521, 533, 536,
	],
	dk: [2, 3, 68, 76, 77, 223, 337, 350, 444, 521, 384],
	ec: [2, 3, 337, 350, 444, 521, 619],
	ee: [2, 3, 76, 350, 444, 521],
	es: [2, 3, 10, 63, 68, 119, 194, 257, 337, 350, 393, 444, 521, 384],
	fi: [2, 3, 68, 76, 77, 223, 337, 350, 444, 521, 384],
	fr: [2, 3, 10, 68, 119, 193, 194, 239, 337, 345, 350, 381, 444, 521, 536],
	gr: [2, 3, 350, 444, 521],
	gt: [2, 3, 337, 350, 444, 521, 619],
	hk: [2, 3],
	hn: [2, 3, 337, 350, 444, 521, 619],
	hu: [2, 3, 350, 444, 521],
	id: [2, 3, 350, 444, 521],
	ie: [2, 3, 68, 99, 223, 337, 350, 444, 521],
	in: [2, 3, 350, 444, 521],
	it: [2, 3, 10, 40, 68, 119, 194, 337, 350, 444, 521],
	jp: [2, 3, 9, 10, 15, 68, 194, 350, 390, 444, 521],
	kr: [3, 444, 521],
	lt: [2, 3, 76, 350, 444, 521],
	lv: [2, 3, 76, 350, 444, 521],
	mx: [2, 3, 10, 68, 119, 269, 337, 350, 444, 464, 521, 619],
	my: [2, 3, 350, 444, 521],
	nl: [2, 3, 10, 68, 119, 194, 223, 297, 337, 350, 389, 444, 521],
	no: [2, 3, 68, 76, 77, 223, 337, 350, 444, 521, 384],
	nz: [2, 3, 68, 99, 269, 337, 350, 357, 444, 521],
	pe: [2, 3, 337, 350, 444, 521, 619],
	ph: [2, 3, 223, 350, 444, 521],
	pl: [2, 3, 40, 350, 444, 521],
	pt: [2, 3, 337, 350, 444, 521],
	py: [2, 3, 337, 350, 444, 521, 619],
	ro: [350, 444, 521],
	ru: [2, 3, 350, 444, 521],
	se: [2, 3, 68, 76, 77, 223, 337, 350, 444, 521, 384],
	sg: [2, 3, 223, 337, 350, 444, 521],
	th: [2, 3, 350, 444, 521],
	tr: [2, 3, 444, 521],
	tw: [2],
	uk: [
		2, 3, 10, 40, 68, 99, 189, 194, 196, 197, 199, 201, 204, 223, 269, 287, 296, 337, 350, 444, 521, 529, 536, 584,
		588, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 618,
	],
	us: [
		2, 3, 7, 9, 10, 15, 60, 68, 99, 105, 143, 194, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 257, 261, 262,
		263, 269, 279, 288, 289, 290, 291, 292, 293, 294, 295, 299, 331, 332, 337, 343, 350, 384, 386, 387, 444, 464,
		521, 529, 531, 536, 582, 583, 584, 613, 617,
	],
	ve: [2, 3, 337, 350, 444, 521, 619],
	za: [2, 3, 350, 444, 521],
};

export const SponsoredClickoutsHelper = {
	isSponsoredOffer(offer: BuyboxOffer, webLocale: WebLocale): boolean {
		return sponsoredProviders[webLocale]?.includes(offer.provider.packageId) ?? false;
	},

	isSponsoredOfferGraphql(offerPackageId: number, webLocale: WebLocale): boolean {
		return sponsoredProviders[webLocale]?.includes(offerPackageId) ?? false;
	},
};
