
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Platform, SraEventAction } from '@/@types/graphql-types';
import {
	TrackSraEventDocument,
	TrackSraEventMutation,
	TrackSraEventMutationVariables,
} from '@/graphql/mutation/TrackSraEvent.mutation';
import { ApplicationContext, TrackingAppId } from '@/helpers/tracking/tracking-helper';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import { getUtmSearchParams } from '@/helpers/composables/useArbitrage';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

const Language = namespace('language');
const Routing = namespace('routing');

export enum EVENT_TYPES {
	SR_EVENT = 'trackSraEvent',
}

const DOMAIN = `https://www.${JW_CONFIG.DOMAIN}`;

/**
 * Mixin to inject Backend driven Tracking functions
 * - trackSraEvent
 */
@Component
export default class BackendTrackingMixin extends Vue {
	@Language.Getter declare country: string;
	@Language.Getter declare language: string;

	@Routing.Getter currentPageType: string;

	get pageUrl() {
		const params = getUtmSearchParams();
		const url = new URL(this.$route.fullPath, DOMAIN);

		if (params) params.forEach((value, key) => url.searchParams.set(key, value));

		return url.href;
	}

	get $backendTracking() {
		const { $apollo, country, currentPageType, language, pageUrl } = this;
		const appId = TrackingAppId;
		const applicationContext = ApplicationContext;

		return {
			trackSraEvent(
				action: SraEventAction,
				sponsoredAd: SponsoredAdFragment | undefined,
				jwEntityID?: string,
				position?: number,
				progress?: number
			) {
				if (currentPageType === null) console.error('Empty Page Type, action: ', action);

				const campaignName = sponsoredAd?.campaign?.name;

				if (!sponsoredAd?.bidId) {
					captureMessageForSentry(
						'[Bid ID is empty]:',
						{
							where: 'Mixin: BackendTracking',
							mutationInfo: {
								action,
								campaignName,
								sponsoredAd: JSON.stringify(sponsoredAd),
								pageType: currentPageType,
								jwEntityID,
								position,
								progress,
							},
						},
						'error',
						{ tag: 'SR', value: campaignName ?? '' }
					);
					return;
				}

				$apollo
					.mutate<TrackSraEventMutation, TrackSraEventMutationVariables>({
						mutation: TrackSraEventDocument,
						variables: {
							input: {
								bidId: sponsoredAd.bidId,
								action,
								sraInput: {
									country,
									platform: Platform.Web,
									pageType: currentPageType,
									language,
									appId,
									applicationContext,
								},
								jwEntityID,
								position,
								progress,
								pageUrl,
							},
						},
					})
					.then(() => {})
					.catch(error => {
						captureMessageForSentry(
							'[GraphQL Track SRA Event]:',
							{
								error,
								where: 'Mixin: BackendTracking',
								mutationInfo: {
									action,
									campaignName: sponsoredAd?.campaign?.name,
									sponsoredAd: JSON.stringify(sponsoredAd),
									pageType: currentPageType,
									jwEntityID,
									position,
									progress,
								},
							},
							'error',
							{ tag: 'SR', value: campaignName ?? '' }
						);
					});
			},
		};
	}
}
