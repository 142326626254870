// List: https://docs.google.com/spreadsheets/d/10H6CRoCg4ZltW2sHnaDItYs4S0hc4qELMrp_oB4ieqg/edit?gid=0#gid=0

import { computed } from 'vue';
import { useLanguageStore, useTitleDetailsStore, useUserStore } from '@/helpers/composables/useStores';

const { isLoggedIn } = useUserStore();
const { titleContext } = useTitleDetailsStore();
const { country } = useLanguageStore();

const excludedPackages = [
	352, 1957, 569, 3, 562, 1771, 192, 33, 349, 1764, 35, 1993, 40, 456, 61, 58, 224, 1862, 641, 2237, 2149, 1948,
];

export const EXCLUDED_PACKAGES = computed(() => {
	let filteredExcludedPackages = excludedPackages;
	// Space for logic e.g. country exceptions

	// YouTube DACH region exceptions (https://justwatch.slack.com/archives/C0UH2TTB6/p1733747644335389?thread_ts=1733733752.284639&cid=C0UH2TTB6)
	// If DACH region and one of the listed titles, include YouTube in the BuyBox
	const ytCountryExceptions = ['DE', 'AT', 'CH'];
	const ytJwEntityIdExceptions = ['tm1159826', 'tm1389634', 'tm1314896']; // Transformers One, Smile 2, Gladiator II
	if (
		ytJwEntityIdExceptions.includes(titleContext.value?.jwEntityId) &&
		ytCountryExceptions.includes(country.value)
	) {
		filteredExcludedPackages = filteredExcludedPackages.filter(packageId => packageId !== 192);
	}

	// If user is not logged in, exclude Crunchyroll package
	if (!isLoggedIn.value) {
		filteredExcludedPackages = [...filteredExcludedPackages, 283];
	}

	return filteredExcludedPackages;
});
