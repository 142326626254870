export type QualityTVPlacements =
	| 'TitlePage'
	| 'SearchPage'
	| 'HomePage'
	| 'SearchBox'

	// AQTV_TitleDetail_Placements
	| 'AQTVEXPControl'
	| 'AQTVEXPV1'
	| 'AQTVEXPV2'
	| 'AQTVEXPV3'
	| 'AQTVEXPV4'
	| 'AQTVEXPV5';
// AQTV_TitleDetail_Placements
type BaseConfig = {
	packageId: number;
	clearName: string;
	technicalName: string;
	icon: string;
	iconWide: string;
};

// 350
type AppleTVConfig = BaseConfig & {
	itsctParams: Record<string, string[]>;
};

// 9, 119
type PrimeConfig = BaseConfig & {
	countries: string[];
	urls: Record<string, { freeTrialUrl: string; posterTag: string; isImpact?: boolean }>;
	default?: string;
};

type QualityTVProviderConfigMap = {
	350: AppleTVConfig;
	9: PrimeConfig;
	119: PrimeConfig;
};

export type QTVPackageIds = keyof typeof qualityTVProviderConfig;

export const qualityTVProviderConfig: QualityTVProviderConfigMap = {
	350: {
		packageId: 350,
		clearName: 'AppleTV+',
		technicalName: 'appletvplus',
		icon: '/icon/152862153/s100/appletvplus.{format}',
		iconWide: '/icon_wide/322151206/s160/appletvplus.{format}',
		itsctParams: {
			justwatch_tv_11: ['FR', 'PT', 'SG', 'SA'],
			justwatch_tv_10: ['MX', 'DE', 'AE'],
			justwatch_tv_4: ['IE', 'AT', 'CH', 'NL', 'ES', 'CR', 'HN', 'ZA'],
			justwatch_tv_13: ['AU', 'CA', 'MY'],
		},
	},
	9: {
		/* Amazon Prime Video */
		packageId: 9,
		clearName: 'Prime Video',
		technicalName: 'amazonprime',
		icon: '/icon/52449539/s100/amazonprime.{format}',
		iconWide: '/icon_wide/322556148/s160/amazonprime.{format}',
		countries: ['US'],
		default: 'https://www.amazon.com/gp/video/offers?tag=justus1ktp-20',
		urls: {
			// DE: {
			// 	freeTrialUrl: 'https://www.amazon.de/gp/video/primesignup?tag=amazon-de-qtv-21',
			// 	posterTag: 'amazon-de-qtv-ti-21',
			// },
			// AT: {
			// 	posterTag: 'amazon-at-qtv-ti-21',
			// 	freeTrialUrl: 'https://www.amazon.de/gp/video/offers?tag=amazon-at-qtv-ft-21',
			// },
			US: {
				posterTag: 'amazon-us-qtv-ti-20',
				freeTrialUrl: 'https://www.amazon.com/gp/video/offers?tag=amazon-us-qtv-ft-20',
			},
		},
	},
	119: {
		/* Amazon Prime Video */
		packageId: 119,
		clearName: 'Prime Video',
		technicalName: 'amazonprimevideo',
		icon: '/icon/52449539/s100/amazonprime.{format}',
		iconWide: 'icon_wide/322556148/s160/amazonprimevideo.{format}',
		// countries: ['FR', 'PT', 'MX', 'IE', 'CH', 'NL', 'ES', 'CR', 'AU', 'CA'],
		countries: [],
		default: 'http://primevideo-eu.pxf.io/B095Wy',
		urls: {
			// FR: {
			// 	posterTag: 'amazon-fr-qtv-ti-21',
			// 	freeTrialUrl: 'https://www.primevideo.com/-/fr/offers?tag=amazon-fr-qtv-ft-21',
			// 	isImpact: false,
			// },
			// PT: {
			// 	posterTag: 'amazon-pt-qtv-ti',
			// 	freeTrialUrl: 'https://primevideo-eu.pxf.io/1rKvQm?subId3=amazon-pt-qtv-ft',
			// 	isImpact: true,
			// },
			// MX: {
			// 	posterTag: 'amazon-mx-qtv-ti',
			// 	freeTrialUrl: 'https://primevideo-row.pxf.io/XYr3NX?subId3=amazon-mx-qtv-ft',
			// 	isImpact: true,
			// },
			// IE: {
			// 	posterTag: 'amazon-ie-qtv-ti',
			// 	freeTrialUrl: 'https://primevideo-eu.pxf.io/1rKvQm?subId3=amazon-ie-qtv-ft',
			// 	isImpact: true,
			// },
			// CH: {
			// 	posterTag: 'amazon-ch-qtv-ti-21',
			// 	freeTrialUrl: 'https://www.primevideo.com/-/fr/offers?tag=amazon-ch-qtv-ft-21',
			// 	isImpact: false,
			// },
			// NL: {
			// 	posterTag: 'amazon-nl-qtv-ti-21',
			// 	freeTrialUrl: 'https://www.primevideo.com/-/nl/offers?tag=amazon-nl-qtv-ft-21',
			// 	isImpact: false,
			// },
			// ES: {
			// 	posterTag: 'amazon-es-qtv-ti-21',
			// 	freeTrialUrl: 'https://www.primevideo.com/-/es/offers?tag=amazon-es-qtv-ft-21',
			// 	isImpact: false,
			// },
			// CR: {
			// 	posterTag: 'amazon-cr-qtv-ti',
			// 	freeTrialUrl: 'https://primevideo-row.pxf.io/XYr3NX?subId3=amazon-cr-qtv-ft',
			// 	isImpact: true,
			// },
			// AU: {
			// 	posterTag: 'amazon-au-qtv-ti-22',
			// 	freeTrialUrl: 'https://www.primevideo.com/-/en/offers?tag=amazon-au-qtv-ft-22',
			// 	isImpact: false,
			// },
			// CA: {
			// 	posterTag: 'amazon-ca-qtv-ti-20',
			// 	freeTrialUrl: 'https://www.primevideo.com/-/en/offers?tag=amazon-ca-qtv-ft-20',
			// 	isImpact: false,
			// },
		},
	},
};

export const appleDefaultParams = {
	itsct: 'justwatch_tv_6',
};

// currently we only show QTV where we have ATV+. expect this could change in the future
//for experiment
export const appleQualityTvCountries = Object.values(qualityTVProviderConfig[350].itsctParams).flat();
export const primeQualityTvCountries = qualityTVProviderConfig[9].countries.concat(
	qualityTVProviderConfig[119].countries
);

export const qualityTvCountries = [
	...new Set(Object.values(qualityTVProviderConfig[350].itsctParams).flat().concat(primeQualityTvCountries)),
];

export const compositionIDSuffixByPlacement: Record<QualityTVPlacements, string> = {
	TitlePage: '',
	SearchPage: '-sp',
	HomePage: '-hm',
	SearchBox: '-sb',

	// AQTV_TitleDetail_Placements
	AQTVEXPControl: '-mc',
	AQTVEXPV1: '-mv1',
	AQTVEXPV2: '-mv2',
	AQTVEXPV3: '-mv3',
	AQTVEXPV4: '-mv4',
	AQTVEXPV5: '-mv5',
	// AQTV_TitleDetail_Placements
};

export function getItsctParam(country: string, placement: QualityTVPlacements): string {
	switch (placement) {
		case 'TitlePage':
			return (
				Object.entries(qualityTVProviderConfig[350].itsctParams).find(([_, countries]) =>
					countries.includes(country)
				)?.[0] ?? appleDefaultParams.itsct
			);
		case 'HomePage':
			return 'justwatch_tv_2';
		case 'SearchBox':
			return 'justwatch_tv_8';
		case 'SearchPage':
			return 'justwatch_tv_9';
		default:
			return appleDefaultParams.itsct;
	}
}

export function getCompositionIdPrefix(packageId: QTVPackageIds) {
	if ([9, 119].includes(packageId)) return 'pv';
	return 'qb';
}
