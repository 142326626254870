import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=AQTV_TITLEDETAIL_PLACEMENTS:control
// ?preferred_variant=AQTV_TITLEDETAIL_PLACEMENTS:variant_1
// ?preferred_variant=AQTV_TITLEDETAIL_PLACEMENTS:variant_2
// ?preferred_variant=AQTV_TITLEDETAIL_PLACEMENTS:variant_3
// ?preferred_variant=AQTV_TITLEDETAIL_PLACEMENTS:variant_4
// ?preferred_variant=AQTV_TITLEDETAIL_PLACEMENTS:variant_5

// ?remove_preferred_experiment=AQTV_TITLEDETAIL_PLACEMENTS

export const AQTVTitleDetailPlacementsName = 'AQTV_TITLEDETAIL_PLACEMENTS' as const;

export type AQTVTitleDetailPlacementsVariants = ToVariants<typeof AQTVTitleDetailPlacementsVariants>;

export const AQTVTitleDetailPlacementsVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
	VARIANT_3: 'variant_3',
	VARIANT_4: 'variant_4',
	VARIANT_5: 'variant_5',
} as const;

export default generate(AQTVTitleDetailPlacementsName, AQTVTitleDetailPlacementsVariants);
