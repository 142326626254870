import type Vue from 'vue';
import { getCurrentInstance } from 'vue';
import { getVm } from '@/helpers/vm-helper';
import { DollarApollo } from 'vue-apollo/types/vue-apollo';

/** Access the data of an apollo query with the given key. */
export function useApolloData<T>(key: string): () => T {
	return () => {
		const { proxy: instance } = getCurrentInstance() ?? { proxy: getVm() };
		const $apollo: DollarApollo<Vue> = instance?.$apollo;

		try {
			// @ts-expect-error Property data is hidden
			return $apollo?.data?.[key];
		} catch (e) {
			console.error(e);
		}
	};
}
