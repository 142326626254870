import type Provider from '@/interfaces/provider';
import type { OfferPresentationType, Offer } from './titles';
import type { Offer as OfferGraphql, PresentationType, PromotedBundleEdge } from '@/@types/graphql-types';
import type { FastOfferFragment, TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';

export interface BuyboxOffer {
	provider: Provider;
	offer: Offer; // original offer
	price: string;
	translatable: false | { name: string; properties?: any };
	presentationType: OfferPresentationType; // @todo not needed -> offer.presentation_type
	url: string;
	inactive?: boolean;
	isReduced?: boolean;
}

export interface BuyboxOfferGraphql {
	provider: Provider;
	offer: OfferGraphql & { deeplinkRoku?: string }; // original offer
	price: string;
	translatable: false | { name: string; properties?: any };
	presentationType: PresentationType; // @todo not needed -> offer.presentation_type
	url: string;
	inactive?: boolean;
	isReduced?: boolean;
}

export const enum BuyboxOfferCategory {
	STREAM = 'stream',
	RENT = 'rent',
	BUY = 'buy',
	PHYSICAL = 'physical',
	BUNDLE = 'bundle',
	CINEMA = 'cinema',
}

export type TitleOffers = {
	stream: TitleOfferFragment[];
	buy: TitleOfferFragment[];
	rent: TitleOfferFragment[];
	free: TitleOfferFragment[];
	fast: FastOfferFragment[];
	cinema: TitleOfferFragment[];
	bundles?: PromotedBundleEdge[];
	offerCount: number;
	maxOfferUpdatedAt: string;
};

export type BuyboxOffers = Partial<Record<BuyboxOfferCategory, BuyboxOffer[]>>;
export type BuyboxOffersGraphql = Partial<Record<BuyboxOfferCategory, BuyboxOfferGraphql[]>>;
